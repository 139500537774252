/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, VStack } from "@chakra-ui/react";
import { columnUserData } from "../../admin/dataTables/variables/columnsData";
import UserApi from "../../../api/user";
import UserTable from "../../admin/dataTables/components/UserTable";
import qs from "qs";

interface AttributesProps {
    Name: string,
    Value: string
};

interface UserDataResponseProps {
    Attributes: AttributesProps[],
    Enabled: boolean,
    UserCreateDate: Date,
    UserLastModifiedDate: Date,
    UserStatus: string,
    Username: string,
};

export interface UserTableDataProps {
    name: string,
    email: string,
    enabled: boolean,
    status: string,
    accountType: string,
    accountStatus: string,
    created: Date,
    updated: Date,
    option?: any
}

const UserManagement: React.FC = () => {
    const [filterInfo, setFilterInfo] = useState<{field: string, value: string | null}>({field: 'email', value: null});
    const [tableData, setTableData] = useState<UserTableDataProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRerender, setIsRerender] = useState<boolean>(false);

    const fetchTableData = async (param: { [x: string]: string | null }) => {
        setIsLoading(true);
        try {
            const res = await UserApi.GetUsers(qs.stringify(param));
            const array: UserTableDataProps[] = res.data && res.data.users.map((item: UserDataResponseProps) => {
                let email: string = '', name: string = '', accountType = '';
                item.Attributes.forEach((it) => {
                    if (it.Name === 'email') email = it.Value;
                    else if (it.Name === 'name') name = it.Value;
                    else if (it.Name === 'custom:account_type') accountType = it.Value;
                });
                return {
                    name: name,
                    email: email,
                    enabled: item.Enabled,
                    status: item.UserStatus,
                    created: item.UserCreateDate,
                    updated: item.UserLastModifiedDate,
                    accountStatus: item.UserStatus,
                    accountType: accountType
                }
            });
            setTableData(array);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const param = {
            [filterInfo.field]: filterInfo.value
        }
        fetchTableData(param);
    }, [filterInfo]);

    useEffect(() => {
        if (isRerender) {
            const param = {
                [filterInfo.field]: filterInfo.value
            }
            fetchTableData(param);
            setIsRerender(false);
        }
    }, [isRerender]);

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <VStack>
                <UserTable
                    columnsData={columnUserData}
                    tableData={tableData}
                    search={filterInfo}
                    setSearch={setFilterInfo}
                    setIsLoading={setIsLoading}
                    setIsRerender={setIsRerender}
                    loading={isLoading}
                />
            </VStack>
        </Box>
    );
}

export default UserManagement;