import React, { FC, useState, useEffect, useMemo } from "react";
import {
  Box,
  VStack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Accordion,
  AccordionIcon,
  HStack,
  Tag,
  TagLabel,
  Flex,
  TagCloseButton,
  Spinner,
  Text,
  Icon,
  useColorModeValue,
  Tooltip,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  IconButton,
  Select as ChakraSelect,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Card from "../../../components/card/Card";
import UserApi from "../../../api/user";
import { MdAccountCircle } from "react-icons/md";
import Select from "react-select";
import AlertModal from "../../../components/modal/AlertModal";
import { useImmer } from "use-immer";
import { useAuth } from "../../../auth-context/auth.context";
import qs from "qs";

const SUBSCRIPTION_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Canceled", value: "canceled" },
  { label: "Trialing", value: "trialing" },
];

interface Invitation {
  client_id: number;
  client_name: string;
  client_email: string;
  client_subscription: string;
  client_payment_status: string;
  client_status: string;
  emails: string[];
  status: string[];
}

interface Bookkeeper {
  id: number;
  email: string;
}

interface Filter {
  client: string;
  bookkeeper: string;
  subscription: string;
}

const Customers: FC = () => {
  const [invitations, setInvitations] = useImmer<Invitation[]>([]);
  const [currentChannelType, setCurrentChannelType] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useImmer<Filter>({
    client: "",
    bookkeeper: "",
    subscription: "",
  });

  const [open, setOpen] = useState<boolean>(false);

  // Add invitation
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bookkeepers, setBookkeepers] = useState<Bookkeeper[]>([]);
  const [currentInvitation, setCurrentInvitation] = useImmer({
    customerId: -1,
    inviteName: "",
    email: "",
    status: "pending",
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const avatarBg = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const fetchInvitations = async (showLoading: boolean = true) => {
    if (showLoading) {
      setIsLoading(true);
    }
    try {
      const { data } = await UserApi.GetInvitations();
      setInvitations(data);
    } catch (err) {
      console.log(err);
      setInvitations([]);
    }
    if (showLoading) {
      setIsLoading(false);
    }
  };

  const fetchBookkeepers = async () => {
    try {
      const { data } = await UserApi.GetBookkeepers();
      setBookkeepers(data);
    } catch (err) {
      console.log(err);
      setBookkeepers([]);
    }
  };

  const fetchInvitationById = async (customerId: number) => {
    try {
      const { data } = await UserApi.GetInvitationById(customerId);
      if (data) {
        setInvitations((draft) => {
          const item = draft.find((item) => item.client_id === customerId);
          if (item) {
            item.client_status = data.client_status;
            item.emails = data.emails ? [...data.emails] : [];
            item.status = data.status ? [...data.status] : [];
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchInvitations();
    fetchBookkeepers();
  }, []);

  const handleSelectInvitation = async (item: Invitation) => {
    try {
      const { data } = await UserApi.GetClient(
        qs.stringify({ email: item.client_email })
      );

      if (data.users.length > 0) {
        const attributes = data.users[0].Attributes;
        attributes.forEach((attribute: any) => {
          if (attribute.Name === "custom:channelType") {
            setCurrentChannelType(attribute.Value);
          } else {
            setCurrentChannelType("web");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveInvitation = async () => {
    setIsSubmitting(true);
    try {
      const res = await UserApi.RemoveInvitation({
        customerId: currentInvitation.customerId,
        email: currentInvitation.email,
      });
      await fetchInvitationById(currentInvitation.customerId);
      onClose();
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
    setOpen(false);
  };

  const handleAddBookkeeper = async () => {
    setIsSubmitting(true);
    try {
      const res = await UserApi.AddInvitation({
        customerId: currentInvitation.customerId,
        email: currentInvitation.email,
        inviteName: currentInvitation.inviteName,
        status: currentInvitation.status,
      });
      await fetchInvitationById(currentInvitation.customerId);
      onClose();
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  const handleClientStatus = async (clientId: number, status: string) => {
    const res = await UserApi.UpdateClientStatus(clientId, status);
    await fetchInvitationById(clientId);
  };

  const filteredInvitations = useMemo(() => {
    let result = invitations;
    if (filters.client.length > 0) {
      result = invitations.filter(
        (invitation: Invitation) =>
          invitation.client_email
            .toLowerCase()
            .includes(filters.client.toLowerCase()) ||
          invitation.client_name
            .toLowerCase()
            .includes(filters.client.toLowerCase())
      );
    }
    if (filters.bookkeeper.length > 0) {
      result = invitations.filter(
        (invitation: Invitation) =>
          invitation.emails &&
          invitation.emails.some((email: string) =>
            email.toLowerCase().includes(filters.bookkeeper.toLowerCase())
          )
      );
    }

    if (filters.subscription.length > 0 && filters.subscription !== "all") {
      result = invitations.filter(
        (invitation: Invitation) =>
          invitation.client_subscription === filters.subscription
      );
    }
    return result;
  }, [invitations, filters]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        direction="column"
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack w="100%" gap={2}>
          <HStack width="100%" justify="center">
            <HStack>
              <Tag colorScheme="green" variant="outline" borderRadius="full">
                Accepted
              </Tag>
              <Tag colorScheme="orange" variant="outline" borderRadius="full">
                Pending
              </Tag>
            </HStack>
          </HStack>

          <HStack w="100%" spacing={5}>
            <FormControl>
              <FormLabel>Search Clients</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={filters.client}
                  onChange={(e) =>
                    setFilters((draft) => {
                      draft.client = e.target.value;
                    })
                  }
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Search Bookkeepers</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={filters.bookkeeper}
                  onChange={(e) =>
                    setFilters((draft) => {
                      draft.bookkeeper = e.target.value;
                    })
                  }
                />
              </InputGroup>
            </FormControl>

            <FormControl w="450px">
              <FormLabel>Subscription</FormLabel>
              <ChakraSelect
                value={filters.subscription}
                onChange={(e) =>
                  setFilters((draft) => {
                    draft.subscription = e.target.value;
                  })
                }
              >
                <option value="">All</option>
                {SUBSCRIPTION_OPTIONS.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </ChakraSelect>
            </FormControl>
          </HStack>

          {isLoading ? (
            <HStack w="100%" justifyContent="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </HStack>
          ) : (
            <Accordion w="100%">
              {filteredInvitations.map((item: Invitation, index: number) => (
                <AccordionItem key={index}>
                  <AccordionButton
                    justifyContent="space-between"
                    onClick={() => handleSelectInvitation(item)}
                  >
                    <HStack w="100%" justifyContent="space-between">
                      <HStack>
                        <Icon
                          as={MdAccountCircle}
                          width="20px"
                          height="20px"
                          color={avatarBg}
                        />
                        <Text color={avatarBg} fontSize="md" fontWeight="700">
                          {item.client_name}
                        </Text>
                        <Text color={textColor} fontSize="md" fontWeight="700">
                          {item.client_email}
                        </Text>
                      </HStack>
                      <HStack>
                        <Tooltip
                          label="Payment Status"
                          hasArrow
                          placement="top"
                        >
                          <Badge
                            colorScheme={
                              item.client_payment_status ? "green" : "red"
                            }
                          >
                            {item.client_payment_status ? "Paid" : "Unpaid"}
                          </Badge>
                        </Tooltip>
                        <Tooltip label="Subscription" hasArrow placement="top">
                          <Tag
                            variant="solid"
                            colorScheme={
                              item.client_subscription === "active"
                                ? "green"
                                : item.client_subscription === "trialing"
                                ? "blue"
                                : "orange"
                            }
                          >
                            {item.client_subscription}
                          </Tag>
                        </Tooltip>
                        <Tooltip
                          label="Show/Hide client"
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            size="xs"
                            colorScheme="teal"
                            aria-label="Show/Hide"
                            variant={
                              item.client_status === "hidden"
                                ? "outline"
                                : "solid"
                            }
                            icon={
                              item.client_status === "hidden" ? (
                                <ViewOffIcon />
                              ) : (
                                <ViewIcon />
                              )
                            }
                            onClick={() =>
                              handleClientStatus(
                                item.client_id,
                                item.client_status === "hidden"
                                  ? "show"
                                  : "hidden"
                              )
                            }
                          />
                        </Tooltip>
                      </HStack>
                    </HStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={8} py={4}>
                    <VStack w="100%">
                      <HStack w="100%">
                        <Text color={textColor} fontSize="md" fontWeight="700">
                          Channel Type:
                        </Text>
                        {!!currentChannelType && (
                          <Badge variant="solid" colorScheme="green">
                            {currentChannelType === "web"
                              ? "Website"
                              : "Mobile device"}
                          </Badge>
                        )}
                      </HStack>

                      <HStack w="100%">
                        <Text color={textColor} fontSize="md" fontWeight="700">
                          Accountants (Bookkeepers)
                        </Text>
                        <HStack></HStack>
                      </HStack>

                      <Flex flexWrap="wrap" gap={1} w="100%">
                        {item.emails &&
                          item.status &&
                          item.emails.map((email: string, index: number) => (
                            <Tag
                              key={index}
                              borderRadius="full"
                              variant="outline"
                              colorScheme={
                                item.status[index] === "accepted"
                                  ? "green"
                                  : "orange"
                              }
                            >
                              <TagLabel>{email}</TagLabel>
                              <TagCloseButton
                                onClick={() => {
                                  setCurrentInvitation((draft) => {
                                    draft.customerId = item.client_id;
                                    draft.email = email;
                                  });
                                  setOpen(true);
                                }}
                              />
                            </Tag>
                          ))}
                        <Tooltip label="Add Bookkeeper" placement="top">
                          <IconButton
                            icon={<AddIcon />}
                            size="xs"
                            colorScheme="green"
                            aria-label="Add Bookkeeper"
                            onClick={() => {
                              onOpen();
                              setCurrentInvitation((draft) => {
                                draft.customerId = item.client_id;
                              });
                            }}
                          />
                        </Tooltip>
                      </Flex>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </VStack>
      </Card>
      <AlertModal
        type="Remove"
        title="Remove Bookkeeper"
        description="Are you going to remove this invitation?"
        open={open}
        onClose={() => setOpen(false)}
        loading={isSubmitting}
        onConfirm={handleRemoveInvitation}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bookkeeper</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                onChange={(e) =>
                  setCurrentInvitation((draft) => {
                    draft.inviteName = e.target.value;
                  })
                }
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Select
                options={bookkeepers.map((bookkeeper) => ({
                  label: bookkeeper.email,
                  value: bookkeeper.email,
                  id: bookkeeper.id,
                }))}
                onChange={(option) => {
                  if (option?.id) {
                    setCurrentInvitation((draft) => {
                      // draft.emailId = option.id;
                      draft.email = option.value;
                    });
                  }
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleAddBookkeeper}
                disabled={
                  currentInvitation.customerId === -1 ||
                  currentInvitation.inviteName === "" ||
                  currentInvitation.email === "" ||
                  isSubmitting
                }
                isLoading={isSubmitting}
              >
                Add
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Customers;
