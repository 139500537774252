import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLogout,
  MdOutlineShoppingCart,
  MdVerifiedUser,
  MdSupervisorAccount,
  MdNotifications,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "./views/admin/default";
import NFTMarketplace from "./views/admin/marketplace";
import Profile from "./views/admin/profile";
import DataTables from "./views/admin/dataTables";

// Auth Imports
import SignIn from "./views/auth/signIn/index";
import SignUp from "./views/auth/signUp/index";
import ResetPassword from "./views/auth/resetPassword/index";
import MFASetup from "./views/auth/mfa/index";
import UserManagement from "./views/auth/users/index";
import ClientManagement from "./views/auth/clients";
import Customers from "./views/auth/customers";
import Notifications from "./views/admin/notifications";
import Operations from "./views/admin/operations";
import MRR from "./views/admin/MRR";

export interface RoutesProps {
  name: string;
  layout: string;
  path: string;
  icon: JSX.Element;
  component: React.FC<{}>;
  secondary?: boolean;
  hide?: boolean;
  collapse?: boolean;
  category?: boolean;
  messageNavbar?: string;
  items?: RoutesProps[] | RoutesProps | undefined;
}

const routes: RoutesProps[] = [
  {
    name: "Clients",
    layout: "/admin",
    path: "/clients",
    icon: (
      <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />
    ),
    component: ClientManagement,
    hide: true,
  },
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Operations and Customer Success",
    layout: "/admin",
    path: "/operations",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: Operations,
  },
  // {
  //   name: "Sales / Growth",
  //   layout: "/admin",
  //   path: "/sales",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   component: DataTables,
  // },
  // {
  //   name: "Customer Success",
  //   layout: "/admin",
  //   path: "/customer-success",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   component: DataTables,
  // },
  {
    name: "MRR",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/mrr",
    component: MRR,
  },
  {
    name: "Notifications",
    layout: "/admin",
    icon: (
      <Icon as={MdNotifications} width="20px" height="20px" color="inherit" />
    ),
    path: "/notifications",
    component: Notifications,
  },
  {
    name: "Client / Bookkeeper",
    layout: "/admin",
    path: "/customers",
    icon: (
      <Icon
        as={MdSupervisorAccount}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Customers,
  },
  {
    name: "User Management",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />
    ),
    component: UserManagement,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
    hide: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: DataTables,
    hide: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hide: true,
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: SignIn,
    hide: true,
  },
  {
    name: "Sign up",
    layout: "/auth",
    path: "/signup",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: SignUp,
    hide: true,
  },
  {
    name: "Reset password",
    layout: "/auth",
    path: "/reset",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hide: true,
  },
  {
    name: "MFA Setup",
    layout: "/auth",
    path: "/mfa/setup",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MFASetup,
    hide: true,
  },
];

export const Logout: RoutesProps[] = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: <Icon as={MdLogout} width="20px" height="20px" color="inherit" />,
    component: SignIn,
  },
];
export default routes;
