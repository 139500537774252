import axios from "./index";

class UserApi {
  static GetUsers = (data: any) => {
    return axios.get(`${base}?${data}`);
  };

  static CreateUser = (data: any) => {
    return axios.post(`${base}`, data);
  };

  static EnableUser = (data: any) => {
    return axios.put(`${base}/enable`, data);
  };

  static DisableUser = (data: any) => {
    return axios.put(`${base}/disable`, data);
  };

  static DeleteUser = (data: any) => {
    return axios.delete(`${base}`, { data: data });
  };

  static GetClients = (email: string) => {
    return axios.post(`${base}/clients`, { email });
  };

  static GetClient = (data: any) => {
    return axios.get(`${base}/client?${data}`);
  };

  static GetAllClients = () => {
    return axios.get(`${base}/clients/all`);
  };

  static GetActiveUserCount = () => {
    return axios.post(`${base}/client/count`);
  };

  static GetInvitations = () => {
    return axios.get(`${base}/invitations`);
  };

  static GetInvitationById = (customerId: number) => {
    return axios.get(`${base}/invitation/${customerId}`);
  };

  static AddInvitation = (data: any) => {
    return axios.post(`${base}/invitation`, data);
  };

  static RemoveInvitation = (data: any) => {
    return axios.delete(`${base}/invitation`, {
      data,
    });
  };

  static GetBookkeepers = () => {
    return axios.get(`${base}/bookkeepers`);
  };

  static GetDisconnectedBankAccounts = (email?: string) => {
    return axios.post(`${base}/banks/disconnected`, {
      email,
    });
  };

  static GetNewClients = (email: string) => {
    return axios.post(`${base}/clients/recent`, { email });
  };

  static UpdateClientStatus = (clientId: number, status: string) => {
    return axios.put(`${base}/client/status`, { customerId: clientId, status });
  };

  static SendNotification = (
    title: string,
    content: string,
    sender: string,
    customerIds: number[],
    customerEmails: string[]
  ) => {
    return axios.post(`${base}/notification`, {
      title,
      content,
      sender,
      customerIds,
      customerEmails,
    });
  };

  static GetNotifications = () => {
    return axios.get(`${base}/notifications`);
  };

  static SetNotificationStatus = (id: number, status: boolean) => {
    return axios.patch(`${base}/notification/status`, { id, status });
  };

  static DeleteNotification = (id: number) => {
    return axios.delete(`${base}/notification`, { data: { id } });
  };

  static GetOperations = () => {
    return axios.get(`${base}/operations`);
  };

  static updateOperation = (
    customerId: number,
    companyPointPerson: string,
    contractStartedAt: string,
    lastCommunicatedAt: string,
    laborHours: number,
    avgSalePrice: number,
    appointmentSet: string,
    leadsGenerated: string,
    newAccountPlatform: string,
    newAccountBookkeeping: string,
    newAccountOther: string,
    leadCost: number,
    emailCost: number
  ) => {
    return axios.put(`${base}/operation`, {
      customerId,
      companyPointPerson,
      contractStartedAt,
      lastCommunicatedAt,
      laborHours,
      avgSalePrice,
      appointmentSet,
      leadsGenerated,
      newAccountPlatform,
      newAccountBookkeeping,
      newAccountOther,
      leadCost,
      emailCost,
    });
  };

  static UploadAvatar = (image: any, name: string, type: string) => {
    return axios.post(`${base}/avatar/upload`, { image, name, type });
  };

  static GetAvatarUploadUrl = (name: string, type: string ) => {
    return axios.post(`${base}/avatar/uploadUrl`, { name, type });
  }

  static RemoveAvatar = (name: string) => {
    return axios.delete(`${base}/avatar`, { data: { name }});
  }

  static GetFileUploadUrl = (name: string, key: string, type: string ) => {
    return axios.post(`${base}/file/uploadUrl`, { name, key, type });
  }

  static GetFileDownloadUrl = (name: string, key: string ) => {
    return axios.post(`${base}/file/downloadUrl`, { name, key });
  }

  static RemoveFile = (name: string, key: string) => {
    return axios.delete(`${base}/file`, { data: { name, key }});
  }

  static GetUserFileList = (name: string) => {
    return axios.post(`${base}/file/list`, { name });
  }
}

let base = "user";

export default UserApi;
