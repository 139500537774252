import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
  Skeleton,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAttachMoney, MdBarChart } from "react-icons/md";
import TransactionTable from "views/admin/default/components/TransactionTable";
import BankAccountTable from "views/admin/default/components/BankAccountTable";
import NewClientsTable from "views/admin/default/components/NewClientsTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import TotalSpent from "views/admin/default/components/TotalSpent";
import TransactionApi from "api/transaction";
import UserApi from "api/user";
import moment from "moment";
import { useAuth } from "auth-context/auth.context";
import { useImmer } from "use-immer";

const TAX_DOCUMENTS = [
  "Schedule C",
  "Form 1120",
  "Form 1120S",
  "Form 1065",
  "Schedule E",
];

export default function MainDashboard() {
  const [grossReceiptsTotal, setGrossReceiptsTotal] = useState(0);
  const [expense, setExpense] = useImmer({
    scheduleC: 0,
    form1120: 0,
    form1120s: 0,
    form1065: 0,
    scheduleE: 0,
  });

  const [activeUserCount, setActiveUserCount] = useState(0);
  const { user, selectedClient } = useAuth();

  const fetchGrossReceiptsTotal = async () => {
    try {
      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      const startDate = moment(firstDay).format("YYYY-MM-DD");
      const endDate = moment(Date.now()).format("YYYY-MM-DD");

      if (user.accountType === "Bookkeeper") {
        const email = selectedClient.email;
        const { data } = await TransactionApi.GetGrossReceiptsTotal(
          startDate,
          endDate,
          email
        );
        setGrossReceiptsTotal(Math.abs(data.grossReceiptsTotal));
      } else {
        const { data } = await TransactionApi.GetGrossReceiptsTotal(
          startDate,
          endDate
        );
        setGrossReceiptsTotal(Math.abs(data.grossReceiptsTotal));
      }
    } catch (e) {
      console.log("fetchGrossReceiptsTotal", e);
      setGrossReceiptsTotal(0);
    }
  };

  const fetchExpenseTotals = async (taxDocumentName) => {
    try {
      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      const startDate = moment(firstDay).format("YYYY-MM-DD");
      const endDate = moment(Date.now()).format("YYYY-MM-DD");

      let expenseTotal = 0;
      if (user.accountType === "Bookkeeper") {
        const email = selectedClient.email;
        const { data } = await TransactionApi.GetExpenseTotalByTaxDocument(
          startDate,
          endDate,
          taxDocumentName,
          email
        );
        expenseTotal = data.expenseTotal;
      } else {
        const { data } = await TransactionApi.GetExpenseTotalByTaxDocument(
          startDate,
          endDate,
          taxDocumentName
        );
        expenseTotal = data.expenseTotal;
      }

      setExpense((draft) => {
        switch (taxDocumentName) {
          case "Schedule C":
            draft.scheduleC = expenseTotal;
            break;
          case "Form 1120":
            draft.form1120 = expenseTotal;
            break;
          case "Form 1120S":
            draft.form1120s = expenseTotal;
            break;
          case "Form 1065":
            draft.form1065 = expenseTotal;
            break;
          case "Schedule E":
            draft.scheduleEe = expenseTotal;
            break;
        }
      });
    } catch (e) {
      console.log("fetchExpenseTotals", e);
    }
  };

  const fetchActiveUserCount = async () => {
    try {
      if (user.accountType === "Bookkeeper") {
        const email = user.attributes.email;
        const { data } = await UserApi.GetClients(email);
        setActiveUserCount(data.length);
      } else {
        const { data } = await UserApi.GetActiveUserCount();
        setActiveUserCount(data.activeUserCount);
      }
    } catch (e) {
      console.log("fetchActiveUserCount", e);
      setActiveUserCount(0);
    }
  };

  useEffect(() => {
    fetchGrossReceiptsTotal();
    if (user.accountType === "Bookkeeper") {
      fetchExpenseTotals(selectedClient.tax_document_name);
    } else {
      TAX_DOCUMENTS.map((taxDocumentName) =>
        fetchExpenseTotals(taxDocumentName)
      );
    }

    // fetchActiveUserCount();
  }, []);

  const expenseSum =
    expense.scheduleC +
    expense.form1120 +
    expense.form1120s +
    expense.form1065 +
    expense.scheduleE;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <TotalSpent />
        {/* <WeeklyRevenue /> */}
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Gross Receipts or Sales"
          value={
            grossReceiptsTotal > 0 ? (
              `$${grossReceiptsTotal.toLocaleString("en-US")}`
            ) : (
              <Skeleton h="25px" w="50%" />
            )
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name="Expense"
          value={
            expenseSum > 0 ? `$${expenseSum.toLocaleString("en-US")}` : <Skeleton h="25px" w="50%" />
          }
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdSupervisorAccount}
                  color={brandColor}
                />
              }
            />
          }
          name="Active Users"
          value={`${activeUserCount}`}
        /> */}
        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineAutoGraph}
                  color={brandColor}
                />
              }
            />
          }
          name="Profit & Loss"
          value={0}
        /> */}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'> */}
        <DailyTraffic />
        <PieCard />
        {/* </SimpleGrid> */}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <TransactionTable />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <BankAccountTable />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <NewClientsTable />
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
