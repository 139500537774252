import React from "react";

// Chakra imports
import { Flex } from "@chakra-ui/react";
import { SynkbooksLogo } from "../../icons/Icons";

// Custom components
import { HSeparator } from "../../separator/Separator";

const SidebarBrand: React.FC = () => {
  //   Chakra color mode
  return (
    <Flex align='center' direction='column'>
      <SynkbooksLogo w="200px" h="40px" mb='20px'/>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
