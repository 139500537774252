// Chakra imports
import React from "react";
import { Flex, Text, VStack, Image } from "@chakra-ui/react";
import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";
// Assets
import { SynkbooksLogo } from "../../components/icons/Icons";

interface AuthIllustrationProps {
  illustrationBackground: string;
  image: string;
  children?: React.ReactNode;
}

const AuthIllustration = ({ children }: AuthIllustrationProps) => {
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <VStack w="fit-content" marginTop="100px">
          <SynkbooksLogo w="290px" h="60px" />
          <Text color="gray.400" fontWeight="400" fontSize="md">
            Synkbooks Dashboard
          </Text>
          <Image src={require("../../assets/img/auth/business-report.png")} w="200px"/>
        </VStack>

        {children}
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}
// PROPS

export default AuthIllustration;