import { useState, useEffect } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";
import {
  AttachmentIcon,
  CheckCircleIcon,
  CheckIcon,
  DeleteIcon,
  DownloadIcon,
  upload,
} from "@chakra-ui/icons";
import { useAuth } from "auth-context/auth.context";
import UserApi from "api/user";
import axios from "axios";

export default function Upload(props) {
  const { used, total, ...rest } = props;

  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]); // { name, uploaded }
  const [selectedFile, setSelectedFile] = useState();
  const [isRemoving, setIsRemoving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const getUserFiles = async (username) => {
    setIsLoading(true);
    const { data: files } = await UserApi.GetUserFileList(username);
    console.log({ files });
    setFileList(
      files.map((file) => ({ name: file.Key.split("/")[2], uploaded: true }))
    );
    setIsLoading(false);
  };

  const onDrop = async (selectedfiles) => {
    setFiles(selectedfiles);

    setFileList([
      ...fileList,
      ...selectedfiles.map((file) => ({ name: file.name, uploaded: false })),
    ]);
  };

  const handleDownload = async (fileItem) => {
    const key = fileItem.name;
    const { data } = await UserApi.GetFileDownloadUrl(user.username, key);
    window.open(data.downloadUrl);
  };

  const handleRemove = async (fileItem) => {
    setIsRemoving(true);
    try {
      const key = fileItem.name;
      if (fileItem.uploaded) {
        await UserApi.RemoveFile(user.username, key);
        toast({
          title: `${key} was deleted successfully.`,
          status: "success",
          isClosable: true,
        });
      }
      setFileList(fileList.filter((item) => item.name !== key));
      onClose();
    } catch (error) {
      toast({
        title: `Deletion failed.`,
        status: "error",
        isClosable: true,
      });
    }
    setIsRemoving(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    for (const file of files) {
      const currentFile = fileList.find((item) => item.name === file.name);
      if (!currentFile.uploaded) {
        const { data } = await UserApi.GetFileUploadUrl(
          user.username,
          file.name,
          file.type
        );
        console.log("data:", data);
        await axios.put(data.uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        let _fileList = [...fileList];
        let _currentFile = _fileList.find((item) => item.name === file.name);
        _currentFile.uploaded = true;
        setFileList(_fileList);

        toast({
          title: `Selected files were uploaded successfully.`,
          status: "success",
          isClosable: true,
        });
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (user.username) {
      getUserFiles(user.username);
    }
  }, [user]);

  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      <Flex
        h="100%"
        direction={{ base: "column", lg: "row", "2xl": "row" }}
        gap="20px"
      >
        <Dropzone
          w={{ base: "100%", lg: "268px", "2xl": "268px" }}
          maxH={{ base: "50%", md: "50%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "50%", md: "50%", lg: "100%", "2xl": "100%" }}
          content={
            <Box>
              <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
              <Flex justify="center" mx="auto" mb="12px">
                <Text fontSize="xl" fontWeight="700" color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
            </Box>
          }
          onDrop={onDrop}
        />
        <Flex direction="column" gap="5px" w={{ base: "100%", lg: "80%" }}>
          {isLoading ? (
            <Stack w="100%">
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : (
            <>
              {fileList.map((file, index) => (
                <HStack key={index}>
                  {file.uploaded ? (
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="brand"
                      aria-label="Done"
                      size="xs"
                      icon={<CheckIcon />}
                    />
                  ) : (
                    <IconButton
                      isRound
                      variant="ghost"
                      aria-label="Done"
                      size="xs"
                      icon={<AttachmentIcon />}
                    />
                  )}
                  <Text
                    color={textColorPrimary}
                    fontWeight="bold"
                    textAlign="start"
                    fontSize="sm"
                  >
                    {file.name}
                  </Text>
                  <Tooltip label="Download" placement="top">
                    <IconButton
                      isRound
                      variant="outline"
                      colorScheme="green"
                      aria-label="Done"
                      size="xs"
                      icon={<DownloadIcon />}
                      onClick={() => handleDownload(file)}
                    />
                  </Tooltip>
                  <Tooltip label="Remove" placement="top">
                    <IconButton
                      isRound
                      variant="outline"
                      colorScheme="red"
                      aria-label="Done"
                      size="xs"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        if (file.uploaded) {
                          setSelectedFile(file);
                          onOpen();
                        } else {
                          setFileList(
                            fileList.filter((item) => item.name !== file.name)
                          );
                        }
                      }}
                    />
                  </Tooltip>
                </HStack>
              ))}
            </>
          )}
          <Flex w="100%" mt="20px">
            <Button
              variant="brand"
              fontWeight="500"
              onClick={handleSubmit}
              disabled={fileList.every((item) => item.uploaded)}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete file
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you going to delete this file?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme="brand"
                onClick={() => handleRemove(selectedFile)}
                ml={3}
                isLoading={isRemoving}
                disabled={isRemoving}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Card>
  );
}
