import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route";
import "./aws-cognito-config";


ReactDOM.render(
  <ChakraProvider theme={theme} cssVarsRoot='#app'>
    <AuthProvider>
      <React.StrictMode>
        <BrowserRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path={`/admin`} component={AdminLayout} />
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </BrowserRouter>
      </React.StrictMode>
    </AuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
