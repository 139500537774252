// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import LineChart from "components/charts/LineChart";
import { useState, useEffect, useMemo } from "react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import TransactionApi from "api/transaction";
import { useAuth } from "auth-context/auth.context";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { useImmer } from "use-immer";

const TAX_DOCUMENTS = [
  "Schedule C",
  "Form 1120",
  "Form 1120S",
  "Form 1065",
  "Schedule E",
];

export default function TotalSpent(props) {
  const { user, selectedClient } = useAuth();

  const [monthlyGrossReceipts, setMonthlyGrossReceipts] = useState([]);
  const [monthlyExpense, setMonthlyExpense] = useImmer({
    scheduleC: [],
    form1120: [],
    form1120s: [],
    form1065: [],
    scheduleE: [],
  });

  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const fetchMonthlyGrossReceiptsTotals = async () => {
    try {
      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      const startDate = moment(firstDay).format("YYYY-MM-DD");
      const endDate = moment(Date.now()).format("YYYY-MM-DD");

      if (user.accountType === "Bookkeeper") {
        const email = selectedClient.email;
        const { data } = await TransactionApi.GetMonthlyGrossReceiptsTotal(
          startDate,
          endDate,
          email
        );
        setMonthlyGrossReceipts(data);
      } else {
        const { data } = await TransactionApi.GetMonthlyGrossReceiptsTotal(
          startDate,
          endDate
        );
        setMonthlyGrossReceipts(data);
      }
    } catch (error) {
      console.log("fetchMonthlyGrossReceiptsTotals/error", error);
      setMonthlyGrossReceipts([]);
    }
  };

  const fetchMonthlyExpenseTotals = async (taxDocumentName) => {
    try {
      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      const startDate = moment(firstDay).format("YYYY-MM-DD");
      const endDate = moment(Date.now()).format("YYYY-MM-DD");
      let expense = [];
      if (user.accountType === "Bookkeeper") {
        const email = selectedClient.email;
        const { data } =
          await TransactionApi.GetMonthlyExpenseTotalByTaxDocument(
            startDate,
            endDate,
            taxDocumentName,
            email
          );
        expense = data;
      } else {
        const { data } =
          await TransactionApi.GetMonthlyExpenseTotalByTaxDocument(
            startDate,
            endDate,
            taxDocumentName
          );
        expense = data;
      }

      console.log({ expense, taxDocumentName });

      setMonthlyExpense((draft) => {
        switch (taxDocumentName) {
          case "Schedule C":
            draft.scheduleC = expense;
            break;
          case "Form 1120":
            draft.form1120 = expense;
            break;
          case "Form 1120S":
            draft.form1120s = expense;
            break;
          case "Form 1065":
            draft.form1065 = expense;
            break;
          case "Schedule E":
            draft.scheduleE = expense;
            break;
          default:
            draft.scheduleC = expense;
        }
      });
    } catch (error) {
      console.log("fetchMonthlyExpenseTotals/error", error);
      setMonthlyGrossReceipts([]);
    }
  };

  useEffect(() => {
    fetchMonthlyGrossReceiptsTotals();
    if (user.accountType === "Bookkeeper") {
      fetchMonthlyExpenseTotals(selectedClient.tax_document_name);
    } else {
      TAX_DOCUMENTS.map((taxDocumentName) =>
        fetchMonthlyExpenseTotals(taxDocumentName)
      );
    }
  }, []);

  useEffect(() => {
    const grossReceipts = monthlyGrossReceipts.map((item) =>
      Math.abs(item.total)
    );
    let expenses = [];

    const d = new Date();
    for (let i = 0; i < d.getMonth() + 1; i++) {
      expenses.push(0);
    }

    monthlyExpense.scheduleC.map(
      (item, index) => (expenses[index] += item.total)
    );
    monthlyExpense.form1120.map(
      (item, index) => (expenses[index] += item.total)
    );
    monthlyExpense.form1120s.map(
      (item, index) => (expenses[index] += item.total)
    );
    monthlyExpense.form1065.map(
      (item, index) => (expenses[index] += item.total)
    );
    monthlyExpense.scheduleE.map(
      (item, index) => (expenses[index] += item.total)
    );

    setChartData([
      {
        name: "Gross Receipts or Sales",
        data: grossReceipts.map((item) => item.toFixed(2)),
      },
      {
        name: "Expense",
        data: expenses.map((item) => item.toFixed(2)),
      },
    ]);

    let options = { ...lineChartOptionsTotalSpent };
    options.xaxis.categories = options.xaxis.categories.slice(0, monthlyGrossReceipts.length);
    setChartOptions(options);
  }, [monthlyGrossReceipts, monthlyExpense]);

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%" gap="10px">
          <Button
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            color={textColorSecondary}
            borderRadius="7px"
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
            />
            This Year
          </Button>
          <Button
            ms="auto"
            align="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            {...rest}
          >
            <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        {/* <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            $37.5K
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Monthly Income & Expense
            </Text>
          </Flex>

          <Flex align="center">
            <Icon as={IoCheckmarkCircle} color="green.500" me="4px" />
            <Text color="green.500" fontSize="md" fontWeight="700">
              On track
            </Text>
          </Flex>
        </Flex> */}
        <Box minH="300px" minW="100%" mt="auto">
          <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="line"
            width="100%"
            height="100%"
          />
        </Box>
      </Flex>
    </Card>
  );
}
