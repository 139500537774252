import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "../../../layouts/auth/Default";
// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";
import { useImmer } from "use-immer";
import qs from "qs";
import UserApi from "../../../api/user";
import { UserRole } from "../../../config/constant";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useImmer({
    current: "",
    new: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useImmer({
    current: false,
    new: false,
    confirm: false,
  });

  const [error, setError] = useState<string>("");
  const [isSigning, setIsSigning] = useState<boolean>(false);

  const history = useHistory();
  const { user, setUser } = useAuth();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const handleResetPassword = async (event: { preventDefault: () => void; }) => {
    if (event) {
      event.preventDefault();
    }

    if (password.new !== password.confirm) {
      setError("Please confirm password again");
      return;
    }

    setIsSigning(true);
    try {
      console.log("user", user);

      const currentUser = await Auth.completeNewPassword(user, password.new);
      console.log("currentUser", currentUser);

      const signedUser = await Auth.signIn(currentUser.username, password.new);
      console.log("signedUser", signedUser);

      setUser(signedUser);
      if (signedUser.challengeName === "MFA_SETUP") {
        history.push("/auth/mfa/setup");
      } else if (signedUser.challengeName === "SMS_MFA" || signedUser.challengeName === "SOFTWARE_TOKEN_MFA") {
        history.push("/auth/signin");
      } else {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userInfo = await UserApi.GetUsers(qs.stringify({email: currentUser.attributes.email}));
        const userAttributes = userInfo.data.users[0].Attributes;
        const accountType = userAttributes.filter((item: {Name: string, Value: string}) => item.Name === 'custom:account_type')[0].Value;
        setUser({
          ...currentUser,
          accountType: accountType
        });
        console.log("currentUser", currentUser);
        if (accountType === UserRole[2]) {
          history.push("/admin/clients");
        } else {
          history.push("/admin/dashboards");
        }
      }
    } catch (error: any) {
      console.log(error);
      const { message, code } = error;
      if (
        code === "NotAuthorizedException" ||
        code === "UserNotFoundException" ||
        code === "PasswordResetRequiredException" ||
        code === "UserNotConfirmedException" ||
        code === "TooManyRequestsException"
      ) {
        setError(message);
      } else {
        setError("Login Error");
      }
    }
    setIsSigning(false);
  };

  return (
    <DefaultAuth illustrationBackground={require("../../../assets/img/auth/auth.png")} image={require("../../../assets/img/auth/auth.png")}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <VStack w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
        </VStack>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <h4
              style={{
                fontSize: ".9em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error}
            </h4>

            <form onSubmit={handleResetPassword}>
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Current password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    defaultValue={password.current}
                    type={showPassword.current ? "text" : "password"}
                    variant="auth"
                    onChange={(event) => {
                      setPassword((draft) => {
                        draft.current = event.target.value;
                      });
                      setError("");
                    }}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={
                        showPassword.current
                          ? RiEyeCloseLine
                          : MdOutlineRemoveRedEye
                      }
                      onClick={() =>
                        setShowPassword((draft) => {
                          draft.current = !draft.current;
                        })
                      }
                    />
                  </InputRightElement>
                </InputGroup>

                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  New Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    defaultValue={password.new}
                    type={showPassword.new ? "text" : "password"}
                    variant="auth"
                    onChange={(event) => {
                      setPassword((draft) => {
                        draft.new = event.target.value;
                      });
                      setError("");
                    }}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={
                        showPassword.new
                          ? RiEyeCloseLine
                          : MdOutlineRemoveRedEye
                      }
                      onClick={() =>
                        setShowPassword((draft) => {
                          draft.new = !draft.new;
                        })
                      }
                    />
                  </InputRightElement>
                </InputGroup>

                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Confirm Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    mb="24px"
                    size="lg"
                    defaultValue={password.confirm}
                    type={showPassword.confirm ? "text" : "password"}
                    variant="auth"
                    onChange={(event) => {
                      setPassword((draft) => {
                        draft.confirm = event.target.value;
                      });
                      setError("");
                    }}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={
                        showPassword.confirm
                          ? RiEyeCloseLine
                          : MdOutlineRemoveRedEye
                      }
                      onClick={() =>
                        setShowPassword((draft) => {
                          draft.confirm = !draft.confirm;
                        })
                      }
                    />
                  </InputRightElement>
                </InputGroup>

                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  colorScheme="brandScheme"
                  isLoading={isSigning}
                  type="submit"
                >
                  Reset Password
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
