import axios from "./index";

class TransactionApi {
  static GetGrossReceiptsTotal = (
    startDate: string,
    endDate: string,
    email?: string
  ) => {
    return axios.post(`${base}/pnl/gross_receipts`, {
      startDate,
      endDate,
      email,
    });
  };

  static GetExpenseTotalByTaxDocument = (
    startDate: string,
    endDate: string,
    taxDocumentName: string,
    email?: string
  ) => {
    return axios.post(`${base}/pnl/expense`, {
      startDate,
      endDate,
      taxDocumentName,
      email,
    });
  };

  static GetMonthlyGrossReceiptsTotal = (
    startDate: string,
    endDate: string,
    email?: string
  ) => {
    return axios.post(`${base}/pnl/gross_receipts/month`, {
      startDate,
      endDate,
      email,
    });
  };

  static GetMonthlyExpenseTotalByTaxDocument = (
    startDate: string,
    endDate: string,
    taxDocumentName: string,
    email?: string
  ) => {
    return axios.post(`${base}/pnl/expense/month`, {
      startDate,
      endDate,
      taxDocumentName,
      email,
    });
  };

  static GetTransactionDetails = (email: string) => {
    return axios.post(`${base}/details`, {
      email,
    });
  };
}

let base = "transaction";

export default TransactionApi;
