import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const TableSpinner: React.FC<{loading: boolean}> = ({loading}) => {
    return (
        <Box
            position='absolute'
            width='100%'
            height='calc( 100% - 40px )'
            zIndex='1'
            hidden={!loading}
        >
            <Spinner
                top='48%'
                left='50%'
                position='absolute'
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
            <Box
                width='100%'
                height='100%'
            />
        </Box>
    );
}

export default TableSpinner;