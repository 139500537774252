import axios from "./index";

export interface GetPaymentsRequest {
  status: string;
  limit: number;
  page?: string;
  email?: string;
}

export interface GetNewClientsRequest {
  status: string;
  limit: number;
  page?: string;
  email?: string;
}

class StripeApi {
  static GetPayments = (data: GetPaymentsRequest) => {
    return axios.post(`${base}/payments`, data);
  };

  static GetNewClients = (data: GetNewClientsRequest) => {
    return axios.post(`${base}/clients/new`, data);
  }

  static GetClientsWithSubscription = () => {
    return axios.post(`${base}/client/all`);
  }

  static GetSubscriptions = () => {
    return axios.post(`${base}/subscriptions`);
  }
}

let base = "stripe";

export default StripeApi;
