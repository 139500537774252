import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
  HStack,
  Skeleton,
  Stack,
  Button,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useMemo, useEffect, useState } from "react";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import StripeApi from "api/stripe";
import moment from "moment";
import { useAuth } from "auth-context/auth.context";

// Assets
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";

const TABLE_COLUMNS = [
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "CURRENCY",
    accessor: "currency",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

const TRANSACTION_STATUS = [
  // {
  //   label: "Canceled",
  //   value: "canceled",
  // },
  {
    label: "Failed",
    value: "failed",
  },
  {
    label: "Succeeded",
    value: "succeeded",
  },
  {
    label: "Processing",
    value: "processing",
  },
  // {
  //   label: "Requires Action",
  //   value: "requires_action",
  // },
  // {
  //   label: "Requires Capture",
  //   value: "requires_capture",
  // },
  // {
  //   label: "Requires Confirmation",
  //   value: "requires_confirmation",
  // },
  // {
  //   label: "Requires Payment Method",
  //   value: "requires_payment_method",
  // },
];

export default function TransactionTable() {
  const { user, selectedClient } = useAuth();

  const [paymentStatus, setTransactionStatus] = useState("failed");
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [nextPageKey, setNextPageKey] = useState(null);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const fetchPayments = async (isInitial = false) => {
    let params = { status: paymentStatus, limit };
    if (isInitial) {
      setCurrentPage(1);
      setPageCount(1);
      setPayments([]);
    } else {
      if (nextPageKey) {
        params.page = nextPageKey;
      } else {
        return;
      }
    }

    setIsLoading(true);
    if (user.accountType === "Bookkeeper") {
      params.email = selectedClient.email;
    }

    const { data: paymentsData } = await StripeApi.GetPayments(params);
    const { data, has_more, next_page } = paymentsData;

    setNextPageKey(next_page);
    if (data) {
      const paymentData = data.map((item) => {
        return {
          amount: item.amount / 100,
          currency: item.currency.toUpperCase(),
          status: TRANSACTION_STATUS.find((el) => el.value === paymentStatus)
            .label,
          description:
            item.description ??
            (item.status === "canceled" ? item.cancellation_reason : ""),
          customer: item.receipt_email,
          date: moment
            .unix(item.status === "canceled" ? item.canceled_at : item.created)
            .format("MMM DD YYYY, hh:mm A"),
        };
      });
      if (isInitial) {
        setPayments(paymentData);
      } else {
        setPayments([...payments, ...paymentData]);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPayments(true);
  }, [paymentStatus]);

  const handlePrev = async () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = async () => {
    setCurrentPage((prev) => prev + 1);
    setPageCount(currentPage + 1);
    if (currentPage + 1 > pageCount) {
      await fetchPayments();
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Payments
        </Text>

        <HStack spacing={7}>
          <HStack>
            <Text color={textColor} fontWeight="600">
              Status
            </Text>
            <Select
              maxW="250px"
              value={paymentStatus}
              onChange={(e) => {
                setCurrentPage(1);
                setPageCount(1);
                setTransactionStatus(e.target.value);
              }}
            >
              {TRANSACTION_STATUS.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </HStack>
          <HStack justify="center">
            <Button
              colorScheme="brandScheme"
              size="sm"
              isDisabled={currentPage === 1}
              onClick={handlePrev}
            >
              Prev
            </Button>
            <Button
              colorScheme="brandScheme"
              size="sm"
              isDisabled={currentPage === pageCount && !nextPageKey}
              onClick={handleNext}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Flex>

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            {TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={index} borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{
                    sm: "10px",
                    lg: "12px",
                  }}
                  color="gray.400"
                >
                  {column.Header}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <Tr key={item}>
                  <Td colSpan={6} p={2}>
                    <Skeleton h="20px"></Skeleton>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <>
              {payments
                .slice((currentPage - 1) * limit, currentPage * limit)
                .map((item, index) => {
                  return (
                    <Tr key={index}>
                      {Object.keys(item).map((key, idx) => {
                        let value = "";

                        if (key === "amount") {
                          value = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {item[key].toLocaleString("en-US", {
                                style: "currency",
                                currency: item.currency,
                              })}
                            </Text>
                          );
                        } else if (key === "status") {
                          value = (
                            <Flex align="center">
                              <Icon
                                w="24px"
                                h="24px"
                                me="5px"
                                color={
                                  item[key] === "Succeeded"
                                    ? "green.500"
                                    : item[key] === "Failed"
                                    ? "red.500"
                                    : "orange.500"
                                }
                                as={
                                  item[key] === "Succeeded"
                                    ? MdCheckCircle
                                    : item[key] === "Failed"
                                    ? MdCancel
                                    : MdOutlineError
                                }
                              />
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {item[key]}
                              </Text>
                            </Flex>
                          );
                        } else {
                          value = (
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {item[key]}
                            </Text>
                          );
                        }

                        return (
                          <Td
                            key={idx}
                            fontSize={{
                              sm: "14px",
                            }}
                            maxH="30px !important"
                            py="8px"
                            minW={{
                              sm: "150px",
                              md: "200px",
                              lg: "auto",
                            }}
                            borderColor="transparent"
                          >
                            {value}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}

              {payments.length === 0 && (
                <Tr>
                  <Td colSpan={6} p={2}>
                    <Text textAlign="center">There is no payments</Text>
                  </Td>
                </Tr>
              )}
            </>
          )}
        </Tbody>
      </Table>
    </Card>
  );
}
