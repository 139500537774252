import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { useHistory } from "react-router-dom";

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  const { user, selectedClient } = useAuth();
  
  if (!user) {
    history.push("/auth/login");
  } else {
    if (
      user.accountType === "Bookkeeper" &&
      !selectedClient &&
      history.location.pathname !== "/admin/clients" && 
      history.location.pathname !== "/admin/profile"
    ) {
      console.log("history.location", history.location);
      history.push("/admin/clients");
    }
  }

  return <Route {...rest} />;
};
