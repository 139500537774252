export const statusOptionArray: {label: string, value: string, color: string}[] = [
    {
        label: 'UNCONFIRMED',           //linkedin
        value: 'UNCONFIRMED',
        color: 'linkedin'
    },
    {
        label: 'CONFIRMED',             //green
        value: 'CONFIRMED',
        color: 'green'
    },
    {
        label: 'EXTERNAL',              //yellow
        value: 'EXTERNAL',
        color: 'yellow'
    },
    {
        label: 'ARCHIVED',              //orange
        value: 'ARCHIVED',
        color: 'orange'
    },
    {
        label: 'UNKNOWN',               //blackAlpha
        value: 'UNKNOWN',
        color: 'blackAlpha'
    },
    {
        label: 'RESET_REQUIRED',        //teal
        value: 'RESET_REQUIRED',
        color: 'teal'
    },
    {
        label: 'FORCE_CHANGE_PASSWORD', //purple
        value: 'FORCE_CHANGE_PASSWORD',
        color: 'purple'
    }
];