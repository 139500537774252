import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    Radio,
    RadioGroup,
    Stack
} from '@chakra-ui/react';
import { UserRole } from '../../../../config/constant';

interface UserAddModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSave: (e: {name: string, email: string, role: string}) => Promise<void>
};

const UserAddModal: React.FC<UserAddModalProps> = ({isOpen, onClose, onSave}) => {
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<string>(UserRole[1]);

    const validate = () => {
        if (!username) {
            setErrorFlag(true);
        } else if (!email) {
            setErrorFlag(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setErrorFlag(true);
        } else {
            onSave({
                name: username,
                email: email,
                role: userRole
            });
            onClose();
            setEmail('');
            setUsername('');
            setUserRole(UserRole[1]);
        }
    }

    const handleEmailChange = (value: string) => {
        setEmail(value);
        setErrorFlag(false);
    }
    
    const handleUsernameChange = (value: string) => {
        setUsername(value);
        setErrorFlag(false);
    }

    const handleClose = () => {
        onClose();
        setEmail('');
        setUsername('');
        setUserRole(UserRole[1]);
        setErrorFlag(false);
    }

    return (
        <Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormControl id='UserInfo' isRequired>
                    {errorFlag ? (
                        <FormHelperText color='red'>
                            {!username ? 'Username is required' : !email ? 'Email is required' : 'Invalid email address'}
                        </FormHelperText>
                    ) : null}
                    <FormLabel>Name</FormLabel>
                    <Input placeholder='Input Username' colorScheme="brandScheme"  value={username} onChange={(e) => handleUsernameChange(e.target.value)} marginBottom='8px' />
                    <FormLabel>Email</FormLabel>
                    <Input placeholder='Input Email' colorScheme="brandScheme"  value={email} onChange={(e) => handleEmailChange(e.target.value)} marginBottom='16px' />
                    <FormLabel>Account Type</FormLabel>
                    <RadioGroup value={userRole} onChange={(e) => setUserRole(e)}>
                        <Stack spacing={5} direction='row'>
                        {
                            UserRole.map((item, index) => (
                                <Radio key={index} colorScheme="brandScheme" value={item}>{item}</Radio>
                            ))
                        }
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="brandScheme" mr={3} onClick={() => validate()}>Save</Button>
                <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
            </ModalFooter>
            </ModalContent>
      </Modal>
    );
}

export default UserAddModal;
