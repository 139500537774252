import React, { FC, useState, useEffect, useMemo } from "react";
import {
  Box,
  VStack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Accordion,
  AccordionIcon,
  HStack,
  Tag,
  TagLabel,
  Flex,
  TagCloseButton,
  Spinner,
  Text,
  Icon,
  useColorModeValue,
  Tooltip,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  IconButton,
  Select as ChakraSelect,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import Card from "../../../components/card/Card";
import UserApi from "../../../api/user";
import {
  MdAccountCircle,
  MdOutlineGroup,
  MdGroupWork,
  MdGroups,
  MdSave,
} from "react-icons/md";
import Select from "react-select";
import AlertModal from "../../../components/modal/AlertModal";
import { useImmer } from "use-immer";
import { useAuth } from "../../../auth-context/auth.context";
import TransactionApi from "../../../api/transaction";
import moment from "moment";
import StripeApi, { GetPaymentsRequest } from "../../../api/stripe";

const TAX_DOCUMENT_TYPE_OPTIONS = [
  { label: "Schedule C", value: "Schedule C" },
  { label: "Form 1120", value: "Form 1120" },
  { label: "Form 1120S", value: "Form 1120S" },
  { label: "Form 1065", value: "Form 1065" },
  { label: "Schedule E", value: "Schedule E" },
];

interface Filter {
  companyName: string;
  businessType: string;
  taxDocumentType: string;
}

interface Operation {
  customer_id: number;
  email: string;
  company_name: string;
  business_type: string;
  tax_form: string;
  stripe_customer_id: string;
  created_at: number;
  company_point_person: string;
  contract_started_at: string;
  last_communicated_at: string;
  labor_hours: number;
  avg_sale_price: number;
  appointment_set: string;
  leads_generated: string;
  new_account_created_platform: string;
  new_account_created_bookkeeping: string;
  new_account_created_other: string;
  lead_cost: number;
  email_cost: number;
}

interface OperationDetails {
  customerId: number;
  email: string;
  grossReceipts: number;
  uncategorizedCount: number;
  pendingTransactionCount: number;
  lastImportDate: string;

  failedStripePayments: number;
  monthlyPaymentAccountCount: number;
  activeCustomer: boolean;

  // Manual Entry
  companyPointPerson: string;
  contractStart: string;
  lastCommunicationDate: string;
  laborHours: number;
  avgSalePrice: number;
  appointmentSet: string;
  leadsGenerated: string;
  newAccountPlatform: string;
  newAccountBookkeeping: string;
  newAccountOther: string;
  leadCost: number;
  emailCost: number;
}

const Operations: FC = () => {
  const [operations, setOperations] = useImmer<Operation[]>([]);
  const [operationDetails, setOperationDetails] = useImmer<OperationDetails>({
    customerId: 0,
    email: "",
    grossReceipts: 0,
    uncategorizedCount: 0,
    pendingTransactionCount: 0,
    lastImportDate: "",

    // Stripe
    failedStripePayments: 0,
    monthlyPaymentAccountCount: 0,
    activeCustomer: false,

    // Manual Entry
    companyPointPerson: "",
    contractStart: "",
    lastCommunicationDate: "",
    laborHours: 0,

    avgSalePrice: 0,
    appointmentSet: "",
    leadsGenerated: "",
    newAccountPlatform: "",
    newAccountBookkeeping: "",
    newAccountOther: "",
    leadCost: 0,
    emailCost: 0,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useImmer<Filter>({
    companyName: "",
    businessType: "",
    taxDocumentType: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const avatarBg = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();

  const fetchOperations = async (showLoading: boolean = true) => {
    if (showLoading) {
      setIsLoading(true);
    }
    try {
      const { data } = await UserApi.GetOperations();
      console.log({ data });
      setOperations(data);
    } catch (err) {
      console.log(err);
      setOperations([]);
    }
    if (showLoading) {
      setIsLoading(false);
    }
  };

  const handleSelectOperation = async (item: Operation) => {
    try {
      setOperationDetails((draft) => {
        draft.customerId = item.customer_id;
        draft.email = item.email;
        draft.grossReceipts = 0;
        draft.uncategorizedCount = 0;
        draft.pendingTransactionCount = 0;
        draft.lastImportDate = "";

        // Stripe
        draft.monthlyPaymentAccountCount = 0;
        draft.activeCustomer = false;

        // Manual Entry
        draft.companyPointPerson = item.company_point_person ?? "";
        draft.contractStart = item.contract_started_at ?? "";
        draft.lastCommunicationDate = item.last_communicated_at ?? "";
        draft.laborHours = item.labor_hours ?? 0;

        draft.avgSalePrice = item?.avg_sale_price ?? 0;
        draft.appointmentSet = item?.appointment_set ?? "";
        draft.newAccountPlatform = item?.new_account_created_platform ?? "";
        draft.newAccountBookkeeping =
          item?.new_account_created_bookkeeping ?? "";
        draft.newAccountOther = item?.new_account_created_other ?? "";
        draft.leadCost = item?.lead_cost ?? 0;
        draft.emailCost = item?.email_cost ?? 0;
      });

      const firstDay = new Date(new Date().getFullYear(), 0, 1);
      const startDate = moment(firstDay).format("YYYY-MM-DD");
      const endDate = moment(Date.now()).format("YYYY-MM-DD");

      TransactionApi.GetGrossReceiptsTotal(startDate, endDate, item.email).then(
        ({ data }) => {
          setOperationDetails((draft) => {
            draft.grossReceipts = Math.abs(data.grossReceiptsTotal);
          });
        }
      );

      TransactionApi.GetTransactionDetails(item.email).then(({ data }) => {
        setOperationDetails((draft) => {
          draft.uncategorizedCount = data.uncategorized_count;
          draft.pendingTransactionCount = data.pending_count;
          draft.lastImportDate = data.last_import_date
            ? moment(data.last_import_date).format("MMM DD, YYYY")
            : "";
        });
      });

      const paymentRequest: GetPaymentsRequest = {
        status: "canceled",
        limit: 100,
        email: item.email,
      };
      const { data: paymentsData } = await StripeApi.GetPayments(
        paymentRequest
      );
      const { data: failedPayments, has_more, next_page } = paymentsData;
      let failedAmounts = 0;
      failedPayments.map(
        (payment: any) => (failedAmounts += payment?.amount / 100)
      );
      console.log({ paymentsData });

      setOperationDetails((draft) => {
        draft.failedStripePayments = failedAmounts;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    console.log({ operationDetails });
    setIsSaving(true);
    try {
      const {
        customerId,
        companyPointPerson,
        contractStart,
        lastCommunicationDate,
        laborHours,

        avgSalePrice,
        appointmentSet,
        leadsGenerated,
        newAccountPlatform,
        newAccountBookkeeping,
        newAccountOther,
        leadCost,
        emailCost,
      } = operationDetails;
      
      await UserApi.updateOperation(
        customerId,
        companyPointPerson,
        contractStart,
        lastCommunicationDate,
        laborHours,
        avgSalePrice,
        appointmentSet,
        leadsGenerated,
        newAccountPlatform,
        newAccountBookkeeping,
        newAccountOther,
        leadCost,
        emailCost
      );
      toast({
        title: `Operation details are saved successfully!`,
        status: "success",
        isClosable: true,
      });
      setOperations((draft) => {
        draft.forEach((operation) => {
          if (operation.customer_id === operationDetails.customerId) {
            operation.company_point_person = companyPointPerson;
            operation.contract_started_at = contractStart;
            operation.last_communicated_at = lastCommunicationDate;
            operation.labor_hours = laborHours;
            operation.avg_sale_price = avgSalePrice;
            operation.appointment_set = appointmentSet;
            operation.leads_generated = leadsGenerated;
            operation.new_account_created_platform = newAccountPlatform;
            operation.new_account_created_bookkeeping = newAccountBookkeeping;
            operation.new_account_created_other = newAccountOther;
            operation.lead_cost = leadCost;
            operation.email_cost = emailCost;
          }
        });
      });
    } catch (err) {
      console.log(err);
      toast({
        title: `${err}`,
        status: "error",
        isClosable: true,
      });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    fetchOperations();
  }, []);

  const filteredOperations = useMemo(() => {
    let result = operations;
    if (filters.companyName.length > 0) {
      result = result.filter((operation) =>
        operation.company_name
          .toLowerCase()
          .includes(filters.companyName.toLowerCase())
      );
    }

    if (filters.businessType.length > 0) {
      result = result.filter((operation) =>
        operation.business_type
          .toLowerCase()
          .includes(filters.businessType.toLowerCase())
      );
    }

    if (filters.taxDocumentType.length > 0) {
      result = result.filter((operation) =>
        operation.tax_form
          .toLowerCase()
          .includes(filters.taxDocumentType.toLowerCase())
      );
    }

    return result;
  }, [operations, filters]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        direction="column"
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <VStack w="100%" gap={2}>
          <HStack w="100%" spacing={5}>
            <FormControl>
              <FormLabel>Company</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={filters.companyName}
                  onChange={(e) =>
                    setFilters((draft) => {
                      draft.companyName = e.target.value;
                    })
                  }
                />
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Business Type</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={filters.businessType}
                  onChange={(e) =>
                    setFilters((draft) => {
                      draft.businessType = e.target.value;
                    })
                  }
                />
              </InputGroup>
            </FormControl>

            <FormControl w="400px">
              <FormLabel>Tax Document Type</FormLabel>
              <ChakraSelect
                value={filters.taxDocumentType}
                onChange={(e) =>
                  setFilters((draft) => {
                    draft.taxDocumentType = e.target.value;
                  })
                }
              >
                <option value="">All</option>
                {TAX_DOCUMENT_TYPE_OPTIONS.map((option, key) => (
                  <option key={key} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </ChakraSelect>
            </FormControl>
          </HStack>

          {isLoading ? (
            <VStack w="100%">
              {Array.from(Array(20)).map((_item, index) => (
                <Skeleton key={index} w="100%" h="20px"></Skeleton>
              ))}
            </VStack>
          ) : (
            <Accordion w="100%">
              {filteredOperations.map((item: Operation, index: number) => (
                <AccordionItem key={index}>
                  <AccordionButton
                    justifyContent="space-between"
                    onClick={() => handleSelectOperation(item)}
                  >
                    <HStack w="100%" justifyContent="space-between">
                      <HStack>
                        <Icon
                          as={MdGroupWork}
                          width="20px"
                          height="20px"
                          color={avatarBg}
                        />
                        <Tooltip label="Company Name" hasArrow placement="top">
                          <Text color={avatarBg} fontSize="md" fontWeight="700">
                            {item.company_name}
                          </Text>
                        </Tooltip>
                        <Tooltip label="Business Type" hasArrow placement="top">
                          <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="700"
                          >
                            - {item.business_type}
                          </Text>
                        </Tooltip>
                      </HStack>

                      <Tooltip
                        label="Tax Document Type"
                        hasArrow
                        placement="top"
                      >
                        <Tag variant="solid" colorScheme="green">
                          {item.tax_form}
                        </Tag>
                      </Tooltip>
                    </HStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={8} py={4}>
                    <VStack gap="20px">
                      <HStack
                        justify="center"
                        align="start"
                        w="100%"
                        gap="50px"
                      >
                        <TableContainer>
                          <Table variant="striped" colorScheme="grey" size="sm">
                            <Tbody>
                              <Tr>
                                <Td fontWeight="bold">Company Name</Td>
                                <Td>{item.company_name}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Email</Td>
                                <Td>{item.email}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Business Type</Td>
                                <Td>{item.business_type}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Tax Document Type</Td>
                                <Td>{item.tax_form}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  Failed Stripe Payments
                                </Td>
                                <Td>{operationDetails.failedStripePayments}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Gross Receipts/Sales</Td>
                                <Td>{operationDetails.grossReceipts}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Pending transactions </Td>
                                <Td>
                                  {operationDetails.pendingTransactionCount}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  Uncategorized Transactions
                                </Td>
                                <Td>{operationDetails.uncategorizedCount}</Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Last Import Date</Td>
                                <Td>{operationDetails.lastImportDate}</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>

                        <TableContainer>
                          <Table variant="striped" colorScheme="grey" size="sm">
                            <Tbody>
                              <Tr>
                                <Td fontWeight="bold">Company Point Person</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.companyPointPerson}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.companyPointPerson =
                                          e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  Date of contract start
                                </Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    type="date"
                                    value={operationDetails.contractStart}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.contractStart = e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  Date of last communication
                                </Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    type="date"
                                    value={
                                      operationDetails.lastCommunicationDate
                                    }
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.lastCommunicationDate =
                                          e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Labor Hours</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.laborHours}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.laborHours = Number(
                                          e.target.value
                                        );
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Average Sale Price</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.avgSalePrice}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.avgSalePrice = Number(
                                          e.target.value
                                        );
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Appointment Set</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.appointmentSet}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.appointmentSet = e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Leads Generated</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.leadsGenerated}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.leadsGenerated = e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  New Account Created - Platform
                                </Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.newAccountPlatform}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.newAccountPlatform =
                                          e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  New Account Created - Bookkeeping
                                </Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={
                                      operationDetails.newAccountBookkeeping
                                    }
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.newAccountBookkeeping =
                                          e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">
                                  New Account Created - Other
                                </Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.newAccountOther}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.newAccountOther = e.target.value;
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Lead Cost</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.leadCost}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.leadCost = Number(e.target.value);
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                              <Tr>
                                <Td fontWeight="bold">Email Cost</Td>
                                <Td>
                                  <Input
                                    size="xs"
                                    value={operationDetails.emailCost}
                                    onChange={(e) =>
                                      setOperationDetails((draft) => {
                                        draft.emailCost = Number(
                                          e.target.value
                                        );
                                      })
                                    }
                                  />
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </HStack>
                      <HStack justify="center" w="100%" gap={20}>
                        <Button
                          size="sm"
                          colorScheme="teal"
                          isLoading={isSaving}
                          leftIcon={<MdSave />}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </HStack>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </VStack>
      </Card>
      {/* <AlertModal
        type="Remove"
        title="Remove Bookkeeper"
        description="Are you going to remove this invitation?"
        open={open}
        onClose={() => setOpen(false)}
        loading={isSubmitting}
        onConfirm={handleRemoveInvitation}
      /> */}
    </Box>
  );
};

export default Operations;
