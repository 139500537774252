import React, { useRef, useState, useEffect } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";
import UserApi from "api/user";
import { useAuth } from "auth-context/auth.context";
import axios from "axios";
import Card from "components/card/Card";

const { REACT_APP_ASSETS_BUCKET_URL } = process.env;

export default function Banner(props) {
  const { banner, avatar, name, job, email, posts, followers, following } =
    props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  const brandColor = useColorModeValue("brand.500", "white");

  const { user } = useAuth();
  const toast = useToast();

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState();
  const [avatarUrl, setAvatarUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageRef = useRef(null);
  const [isUploading, setIsUploaidng] = useState(false);

  useEffect(() => {
    setAvatarUrl(`${REACT_APP_ASSETS_BUCKET_URL}/avatar/${user?.username}`);
  }, [user]);

  const handleSelectImage = async (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageFile(file);
    setImageUrl(url);
  };

  const handleSaveImage = async (e) => {
    setIsUploaidng(true);

    try {
      if (imageUrl) {
        const { data } = await UserApi.GetAvatarUploadUrl(
          user.username,
          imageFile.type
        );

        const result = await axios.put(data.uploadUrl, imageFile, {
          headers: {
            "Content-Type": imageFile.type,
          },
        });

        if (result.status === 200) {
          setAvatarUrl(imageUrl);
        }
      } else {
        const response = await UserApi.RemoveAvatar(user.username);
        console.log({ response });
        setAvatarUrl(null);
      }

      onClose();
    } catch (error) {
      console.log(error);
      toast({
        title: `Edit image error!`,
        status: "error",
        isClosable: true,
      });
    }

    setIsUploaidng(false);
  };

  const handelRemoveAvatar = async () => {
    setImageUrl(null);
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align="center">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="170px"
        w="100%"
      />
      <Box position="relative">
        <Avatar
          mx="auto"
          src={avatarUrl}
          h="140px"
          w="140px"
          mt="-70px"
          border="4px solid"
          borderColor={borderColor}
        />

        <Tooltip label="Edit" placement="top">
          <IconButton
            pos="absolute"
            top="-60px"
            left="calc(50% - 70px)"
            isRound
            colorScheme="brand"
            icon={<EditIcon />}
            size="sm"
            onClick={() => {
              setImageUrl(avatarUrl);
              onOpen();
            }}
          />
        </Tooltip>
      </Box>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={brandColor} fontSize="sm" fontWeight="bold">
        {job}
      </Text>
      <Text color={textColorPrimary} fontSize="md">
        {email}
      </Text>
      {/* <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex> */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center">
              <Tooltip label="Upload image" placement="top">
                <Avatar
                  mx="auto"
                  src={imageUrl}
                  h="170px"
                  w="170px"
                  border="4px solid"
                  borderColor={borderColor}
                  _hover={{
                    opacity: 0.7,
                  }}
                  onClick={() => imageRef.current.click()}
                />
              </Tooltip>
            </Box>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={imageRef}
              onChange={handleSelectImage}
            />
            <Tooltip label="Remove" placement="top">
              <IconButton
                float="right"
                variant="ghost"
                colorScheme="brand"
                icon={<DeleteIcon />}
                onClick={handelRemoveAvatar}
              />
            </Tooltip>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="brand"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSaveImage}
              isLoading={isUploading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
