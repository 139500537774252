import React, { FC, useRef, useMemo } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
} from "@chakra-ui/react";

export type AlertModalType =
  | "Add"
  | "Remove"
  | "Update"
  | "Delete"
  | "Ok"
  | "Confirm";

interface AlertModalProps {
  title: string;
  description: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type: AlertModalType;
  loading: boolean;
}

const AlertModal: FC<AlertModalProps> = ({
  title,
  description,
  open,
  onClose,
  onConfirm,
  type,
  loading = false
}: AlertModalProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const btnText = useMemo(() => {
    if (type === "Add") {
      return "Add";
    } else if (type === "Remove") {
      return "Remove";
    } else if (type === "Update") {
      return "Update";
    } else if (type === "Delete") {
      return "Delete";
    } else {
      return "Ok";
    }
  }, [type]);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3} isLoading={loading} disabled={loading}>
              {btnText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertModal;
