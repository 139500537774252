import { Column } from "react-table";
import { UserTableDataProps } from "../../../../views/auth/users";

export const columnsDataDevelopment = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "TECH",
        accessor: "tech",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
];

export const columnsDataCheck = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
    {
        Header: "QUANTITY",
        accessor: "quantity",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
];

export const columnsDataColumns = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
    {
        Header: "QUANTITY",
        accessor: "quantity",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
];

export const columnsDataComplex = [
    {
        Header: "NAME",
        accessor: "name",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "DATE",
        accessor: "date",
    },
    {
        Header: "PROGRESS",
        accessor: "progress",
    },
];

export const columnUserData: Column<UserTableDataProps>[] = [
    {
        Header: 'NAME',
        accessor: 'name'
    },
    {
        Header: 'EMAIL',
        accessor: 'email'
    },
    {
        Header: 'ACCOUNT TYPE',
        accessor: 'accountType'
    },
    {
        Header: 'ACCOUNT STATUS',
        accessor: 'accountStatus'
    },
    {
        Header: 'UPDATED',
        accessor: 'updated'
    },
    {
        Header: 'CREATED',
        accessor: 'created'
    },
    {
        Header: 'ENABLED',
        accessor: 'enabled',
    },
    {
        Header: 'OPTION',
        accessor: 'option'
    }
];