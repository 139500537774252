import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  HStack,
  Skeleton,
  Button,
} from "@chakra-ui/react";
import React, { useMemo, useEffect, useState } from "react";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import StripeApi from "api/stripe";
import moment from "moment";
import { useAuth } from "auth-context/auth.context";

const TABLE_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Create date",
    accessor: "bankName",
  },
];
export default function NewClientsTable() {
  const [newClients, setNewClients] = useState([]);

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [nextPageKey, setNextPageKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const fetchNewClients = async (isInitial = false) => {
    let params = { limit };
    if (isInitial) {
      setCurrentPage(1);
      setPageCount(1);
      setNewClients([]);
    } else {
      if (nextPageKey) {
        params.page = nextPageKey;
      } else {
        return;
      }
    }

    setIsLoading(true);
    try {
      const { data: clientsData } = await StripeApi.GetNewClients(params);
      const { data, next_page } = clientsData;

      setNextPageKey(next_page);
      if (data) {
        const clients = data.map((item) => ({
          name: item.name ?? item.description,
          email: item.email,
          createdAt: moment.unix(item.created).format("MMM DD YYYY"),
        }));

        if (isInitial) {
          setNewClients(clients);
        } else {
          setNewClients([...newClients, ...clients]);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNewClients(true);
  }, []);

  const handlePrev = async () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleNext = async () => {
    setCurrentPage((prev) => prev + 1);
    setPageCount(currentPage + 1);
    if (currentPage + 1 > pageCount) {
      await fetchNewClients();
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          New Clients
        </Text>
        <HStack justify="center">
          <Button
            colorScheme="brandScheme"
            size="sm"
            isDisabled={currentPage === 1}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            colorScheme="brandScheme"
            size="sm"
            isDisabled={currentPage === pageCount && !nextPageKey}
            onClick={handleNext}
          >
            Next
          </Button>
        </HStack>
      </Flex>

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            {TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={index} borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{
                    sm: "10px",
                    lg: "12px",
                  }}
                  color="gray.400"
                >
                  {column.Header}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <Tr key={item}>
                  <Td colSpan={6} p={2}>
                    <Skeleton h="20px"></Skeleton>
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <>
              {newClients
                .slice((currentPage - 1) * limit, currentPage * limit)
                .map((item, index) => {
                  return (
                    <Tr key={index}>
                      {Object.keys(item).map((key, idx) => (
                        <Td
                          key={idx}
                          fontSize={{
                            sm: "14px",
                          }}
                          maxH="30px !important"
                          py="8px"
                          minW={{
                            sm: "150px",
                            md: "200px",
                            lg: "auto",
                          }}
                          borderColor="transparent"
                        >
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {key === "createdAt"
                              ? moment(item[key]).format("MMM DD YYYY")
                              : item[key]}
                          </Text>
                        </Td>
                      ))}
                    </Tr>
                  );
                })}

              {newClients.length === 0 && (
                <Tr>
                  <Td colSpan={6} p={2}>
                    <Text textAlign="center">There is no new clients.</Text>
                  </Td>
                </Tr>
              )}
            </>
          )}
        </Tbody>
      </Table>
    </Card>
  );
}
