import { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  HStack,
  Skeleton,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  Textarea,
  useToast,
  Badge,
  Spacer,
  IconButton,
  Select as ChakraSelect,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogHeader,
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import moment from "moment";
import UserApi from "../../../api/user";
import { useImmer } from "use-immer";
import Select from "react-select";
import { useAuth } from "../../../auth-context/auth.context";
import { MdSend } from "react-icons/md";
import {
  AddIcon,
  SearchIcon,
  ViewIcon,
  ViewOffIcon,
  DeleteIcon,
} from "@chakra-ui/icons";

const TABLE_COLUMNS = [
  "Title",
  "Content",
  "Sender",
  "Customer",
  "Read",
  "Status",
  "Created At",
  "Updated At",
];

interface Customer {
  customer_id: number;
  customer_email: string;
}

interface Notification {
  id: number;
  title: string;
  content: string;
  sender_email: string;
  customer_email: string;
  read: boolean;
  active: boolean;
  created_at: number;
  updated_at: number;
}

const Notifications: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // Send Notification
  const {
    isOpen: openNotification,
    onOpen: onOpenNotification,
    onClose: onCloseNotification,
  } = useDisclosure();
  const [isSending, setIsSending] = useState(false);
  const [customerIds, setCustomerIds] = useState<number[]>([]);
  const [customerEmails, setCustomerEmails] = useState<string[]>([]);
  const [notification, setNotification] = useImmer({
    title: "",
    content: "",
  });
  const [customers, setCustomers] = useState<Customer[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [notificationId, setNotificationId] = useState<number>(-1);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const fetchCustomers = async () => {
    try {
      const { data } = await UserApi.GetAllClients();
      setCustomers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await UserApi.GetNotifications();
      setNotifications(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCustomers();
    fetchNotifications();
  }, []);

  const handleSendNotification = async () => {
    setIsSending(true);
    try {
      await UserApi.SendNotification(
        notification.title,
        notification.content,
        user.attributes.email,
        customerIds,
        customerEmails
      );
      onCloseNotification();
      toast({
        title: "Send Notification",
        description: "Sent notification to the customers successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      await fetchNotifications();
      setNotification({
        title: "",
        content: "",
      });
      setCustomerIds([]);
    } catch (error) {
      console.log(error);
    }
    setIsSending(false);
  };

  const handleDeleteNotification = async () => {
    setLoading(true);
    try {
      await UserApi.DeleteNotification(notificationId);
      toast({
        title: "Delete Notification",
        description: "Notification was deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      await fetchNotifications();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleStatusChange = async (id: number, status: boolean) => {
    try {
      await UserApi.SetNotificationStatus(id, status);
      

      toast({
        description: "Notification status was updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      const notificationList = [...notifications];
      const selectedItem = notificationList.find(item => item.id === id);
      if (selectedItem) {
        selectedItem.active = status;
        setNotifications(notificationList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredNotifications = (() => {
    return notifications.filter(
      (notification) =>
        notification.title.toLowerCase().includes(search.toLowerCase()) ||
        notification.content.toLowerCase().includes(search.toLowerCase()) ||
        notification.customer_email.toLowerCase().includes(search.toLowerCase())
    );
  })();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        direction="column"
        w="100%"
        px="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <HStack justify={"space-between"}>
          <Button
            leftIcon={<MdSend />}
            colorScheme="blue"
            onClick={onOpenNotification}
            size="sm"
          >
            Send notifications
          </Button>
          <Spacer />
          <InputGroup width="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search Notifications"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </HStack>

        <Table variant="simple" color="gray.500" mt={3}>
          <Thead>
            <Tr>
              {TABLE_COLUMNS.map((column, index) => (
                <Th pe="10px" key={index} borderColor={borderColor}>
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{
                      sm: "10px",
                      lg: "12px",
                    }}
                    color="gray.400"
                  >
                    {column}
                  </Flex>
                </Th>
              ))}
              <Th>...</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                  <Tr key={item}>
                    <Td colSpan={9} p={2}>
                      <Skeleton h="20px"></Skeleton>
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {filteredNotifications.map((item, index) => (
                  <Tr key={index}>
                    {Object.keys(item).map(
                      (key, idx) =>
                        key !== "id" && (
                          <Td
                            key={idx}
                            fontSize={{
                              sm: "14px",
                            }}
                            maxH="30px !important"
                            py="8px"
                            minW={{
                              sm: "150px",
                              md: "200px",
                              lg: "auto",
                            }}
                            borderColor="transparent"
                          >
                            {(key === "title" ||
                              key === "content" ||
                              key === "sender_email" ||
                              key === "customer_email") && (
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {item[key]}
                              </Text>
                            )}
                            {key === "read" && (
                              <>
                                {item[key] ? (
                                  <Badge colorScheme="green">Read</Badge>
                                ) : (
                                  <Badge>Unread</Badge>
                                )}
                              </>
                            )}

                            {key === "active" && (
                              <>
                                <ChakraSelect
                                  bg={item[key] ? "#38A169" : "#E53E3E"}
                                  size="xs"
                                  color="white"
                                  borderRadius="5px"
                                  width="85px"
                                  value={`${item[key]}`}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      item.id,
                                      e.target.value === "true"
                                    )
                                  }
                                >
                                  <option value="true">Active</option>
                                  <option value="false">Inactive</option>
                                </ChakraSelect>
                              </>
                            )}

                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {(key === "created_at" || key === "updated_at") &&
                                moment(item[key]).format("MMM DD YYYY")}
                            </Text>
                          </Td>
                        )
                    )}
                    <Td colSpan={8} p={2}>
                      <IconButton
                        aria-label="Delete notification"
                        size="xs"
                        colorScheme="red"
                        variant="outline"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          setNotificationId(item.id);
                          onOpen();
                        }}
                      />
                    </Td>
                  </Tr>
                ))}

                {filteredNotifications.length === 0 && (
                  <Tr>
                    <Td colSpan={9} p={2}>
                      <Text textAlign="center">There is no notifications.</Text>
                    </Td>
                  </Tr>
                )}
              </>
            )}
          </Tbody>
        </Table>
      </Card>
      <Modal isOpen={openNotification} onClose={onCloseNotification} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={3}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="Title"
                onChange={(e) =>
                  setNotification((draft) => {
                    draft.title = e.target.value;
                  })
                }
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Content</FormLabel>
              <Textarea
                rows={3}
                placeholder="Content"
                onChange={(e) =>
                  setNotification((draft) => {
                    draft.content = e.target.value;
                  })
                }
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Clients</FormLabel>
              <Select
                isMulti
                closeMenuOnSelect={false}
                value={customers
                  .filter((cus) => customerIds.includes(cus.customer_id))
                  .map((customer) => ({
                    label: customer.customer_email,
                    value: customer.customer_id,
                    id: customer.customer_id,
                  }))}
                options={customers.map((customer) => ({
                  label: customer.customer_email,
                  value: customer.customer_id,
                  id: customer.customer_id,
                }))}
                onChange={(options) => {
                  setCustomerIds(options.map((option) => option.value));
                  setCustomerEmails(options.map((option) => option.label));
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onCloseNotification}>Cancel</Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSendNotification}
                disabled={
                  notification.title === "" ||
                  notification.content === "" ||
                  customerIds.length === 0 ||
                  isSending
                }
                isLoading={isSending}
              >
                Send
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Notification
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                colorScheme="red"
                onClick={handleDeleteNotification}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Notifications;
