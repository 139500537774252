import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "../../../layouts/auth/Default";
// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";
import UserApi from "../../../api/user";
import qs from "qs";
import { UserRole } from "../../../config/constant";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>(""); // <-- Default values HERE
  const [password, setPassword] = useState<string>(""); // <-- Default values HERE
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [mfaType, setMfaType] = useState<"SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null | undefined>(null);
  const [mfaCode, setMfaCode] = useState<string>("");

  const history = useHistory();
  const { user, setUser } = useAuth();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleLogin = async (event: { preventDefault: () => void; }) => {
    if (event) {
      event.preventDefault();
    }
    setIsSigning(true);
    try {
      const signInUser = await Auth.signIn(email.trim(), password);
      setUser(signInUser);

      if (signInUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        history.push("/auth/reset");
      } else if (signInUser.challengeName === "MFA_SETUP") {
        history.push("/auth/mfa/setup");
      } else if (
        signInUser.challengeName === "SMS_MFA" ||
        signInUser.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setMfaType(signInUser.challengeName);
      } else {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log({currentUser});
        const userInfo = await UserApi.GetUsers(qs.stringify({email: currentUser.attributes.email}));
        const userAttributes = userInfo.data.users[0].Attributes;
        const accountType = userAttributes.filter((item: {Name: string, Value: string}) => item.Name === 'custom:account_type')[0].Value;
        setUser({
          ...currentUser,
          accountType: accountType
        });

        if (accountType === UserRole[2]) {
          history.push("/admin/clients");
        } else {
          history.push("/admin/dashboards");
        }
      }
    } catch (error: any) {
      const { message, code } = error;
      if (
        code === "NotAuthorizedException" ||
        code === "UserNotFoundException" ||
        code === "PasswordResetRequiredException" ||
        code === "UserNotConfirmedException" ||
        code === "TooManyRequestsException"
      ) {
        setError(message);
      } else {
        setError("Login Error");
      }
    }
    setIsSigning(false);
  };

  const handleConfirmCode = async (event: { preventDefault: () => void; }) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const loggedUser = await Auth.confirmSignIn(user, mfaCode, mfaType);
      setUser(loggedUser);
    } catch (error) {
    }
  };

  useEffect(() => {
    if (
      user?.challengeName === "SMS_MFA" ||
      user?.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      setMfaType(user?.challengeName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultAuth illustrationBackground="../../../assets/img/auth/auth.png" image="../../../assets/img/auth/auth.png">
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection="column"
      >
        <VStack w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
        </VStack>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <h4
              style={{
                fontSize: ".9em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error}
            </h4>
            
            {!mfaType ? (
              <form onSubmit={handleLogin}>
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="mail@example.com"
                    mb="24px"
                    defaultValue={email}
                    fontWeight="500"
                    size="lg"
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setError(undefined);
                    }}
                  />

                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="24px"
                      size="lg"
                      defaultValue={password}
                      type={show ? "text" : "password"}
                      variant="auth"
                      onChange={(event) => {
                        setPassword(event.target.value);
                        setError(undefined);
                      }}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    colorScheme="brandScheme"
                    isLoading={isSigning}
                    type="submit"
                  >
                    Sign In
                  </Button>
                </FormControl>
              </form>
            ) : (
              <form onSubmit={handleConfirmCode}>
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Verify Code<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="number"
                    placeholder="123456"
                    mb="24px"
                    defaultValue={email}
                    fontWeight="500"
                    size="lg"
                    onChange={(event) => {
                      setMfaCode(event.target.value);
                    }}
                  />

                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    colorScheme="brandScheme"
                    isLoading={isSigning}
                    type="submit"
                  >
                    Confirm
                  </Button>
                </FormControl>
              </form>
            )}
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
