import React from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "../../components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "../../components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useAuth } from "../../auth-context/auth.context";
import { UserRole } from "../../config/constant";

// Assets
import { IoMenuOutline } from "react-icons/io5";
import { RoutesProps } from "../../routes";

interface SidebarProps {
  routes: RoutesProps[];
  logoText?: string;
  variant?: string;
  display?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ routes }) => {
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="250px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
};

// FUNCTIONS
export const SidebarResponsive: React.FC<SidebarProps> = (props) => {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { routes } = props;

  const { user } = useAuth();
  const customRoutes: RoutesProps[] =
    user && user.accountType === UserRole[2]
      ? routes.map((item) => {
          if (item.name === "Clients") {
            return {
              ...item,
              hide: false,
            };
          } else if (item.name === "User Management") {
            return {
              ...item,
              hide: true,
            };
          } else if (item.name === "Client / Bookkeeper") {
            return {
              ...item,
              hide: true,
            };
          } else {
            return item;
          }
        })
      : routes;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
      >
        <DrawerOverlay />
        <DrawerContent w="250px" maxW="250px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="250px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={customRoutes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Sidebar;
