/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect, useMemo, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { columnUserData } from "../../admin/dataTables/variables/columnsData";
import UserApi from "../../../api/user";
import UserTable from "../../admin/dataTables/components/UserTable";
import qs from "qs";
import { useAuth } from "../../../auth-context/auth.context";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
  HStack,
  Switch,
  Badge,
  useDisclosure,
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  Box,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Card from "../../../components/card/Card";
import TableSpinner from "../../../components/spinner/TableSpinner";
import moment from "moment";
import { SearchIcon, ViewIcon, InfoIcon } from "@chakra-ui/icons";
import { MdMenuBook } from "react-icons/md";

interface ClientData {
  full_name: string;
  email: string;
  tax_document_name: string;
  updated_at: string;
  options?: any;
}

const CLIENT_COLUMNS: Column<ClientData>[] = [
  {
    Header: "NAME",
    accessor: "full_name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "CLIENT SINCE",
    accessor: "updated_at",
  },
  {
    Header: "",
    accessor: "options",
  },
];

const ClientList: React.FC = () => {
  const { user, setSelectedClient } = useAuth();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [clients, setClients] = useState<ClientData[]>([]);
  const [emailSearch, setEmailSearch] = useState<string>("");

  const filteredClients = useMemo(() => {
    return clients.filter(
      (client) =>
        client.email.toLowerCase().includes(search.toLocaleLowerCase()) ||
        client.full_name.toLowerCase().includes(search.toLocaleLowerCase())
    );
  }, [search, clients]);
  const tableInstance = useTable(
    {
      columns: CLIENT_COLUMNS,
      data: filteredClients,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const res = await UserApi.GetClients(user.attributes.email);
      const _clients: ClientData[] = res.data;
      setClients(_clients);
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const handleViewBooks = (data: any) => {
    console.log({data});
    const clientData: ClientData = { ...data };
    history.push("/admin/dashboard");
    setSelectedClient(clientData);
  };

  useEffect(() => {
    console.log("user", user);
    if (user?.attributes) {
      fetchClients();
    }
  }, [user]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
          opacity={isLoading ? 0.7 : 1}
        >
          <TableSpinner loading={isLoading} />
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <HStack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="input"
                  width="300px"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </HStack>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    _hover={{ background: "#dddddd" }}
                  >
                    {row.cells.map((cell, index) => {
                      let data: ReactNode;
                      if (
                        ["NAME", "EMAIL"].includes(cell.column.Header as string)
                      ) {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "CLIENT SINCE") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {moment(cell.value).format("MMMM DD, YYYY")}
                          </Text>
                        );
                      } else {
                        data = (
                          <HStack>
                            <Button
                              rightIcon={<MdMenuBook />}
                              size="sm"
                              fontSize="sm"
                              variant="brand"
                              fontWeight="500"
                              onClick={() => handleViewBooks(row.original)}
                            >
                              View Books
                            </Button>
                          </HStack>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      </VStack>
    </Box>
  );
};

export default ClientList;
