import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import moment from "moment";
import StripeApi from "../../../api/stripe";
import { useAuth } from "../../../auth-context/auth.context";
import {
  barChartOptionsDailyTraffic,
  lineChartOptionsTotalSpent,
} from "../../../variables/charts";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface Client {
  id: number;
  email: string;
  subscriptions: any;
}

const MRR: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();

  const [clients, setClients] = useState<Client[]>([]);
  const [subscriptions, setSubscriptions] = useState<any>([]);

  const [mrrChartType, setMrrChartType] = useState("all");
  const [mrrChartData, setMrrChartData] = useState<ApexOptions["series"]>([
    {
      name: "MRR",
      data: [],
    },
  ]);
  const [mrrChartOptions, setMrrChartOptions] = useState<ApexOptions>({});
  const [lastMrr, setLastMrr] = useState(0);

  const [mrrChartType1, setMrrChartType1] = useState("all");
  const [mrrChartData1, setMrrChartData1] = useState<ApexOptions["series"]>([
    {
      name: "MRR",
      data: [],
    },
  ]);
  const [mrrChartOptions1, setMrrChartOptions1] = useState<ApexOptions>({});
  const [lastMrr1, setLastMrr1] = useState(0);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const fetchClients = async () => {
    try {
      const { data } = await StripeApi.GetClientsWithSubscription();
      setClients(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubscriptons = async () => {
    try {
      const { data } = await StripeApi.GetSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchClients();
    fetchSubscriptons();
  }, []);

  const getYTDChartData = () => {
    setMrrChartData([
      {
        name: "This Year",
        data: [],
      },
      {
        name: "Last Year",
        data: [],
      },
    ]);
    setMrrChartOptions({});
    setLastMrr(0);

    const mrrData: number[] = [];
    const mrrData1: number[] = [];
    const d = new Date();
    for (let i = 0; i < d.getMonth() + 1; i++) {
      mrrData.push(0);
      mrrData1.push(0);
      const firstDay = new Date(d.getFullYear(), i - 1, 1).getTime() / 1000;
      const lastDay = new Date(d.getFullYear(), i, 0).getTime() / 1000;

      const firstDay1 =
        new Date(d.getFullYear() - 1, i - 1, 1).getTime() / 1000;
      const lastDay1 = new Date(d.getFullYear() - 1, i, 0).getTime() / 1000;

      // clients.forEach((client) => {
      // client.
      subscriptions.forEach((subscription: any) => {
        const {
          created: createdAt,
          canceled_at: canceledAt,
          start_date: startedAt,
          ended_at: endedAt,
          plan: { interval, amount, nickname },
          status,
        } = subscription;

        if (
          mrrChartType === "all" ||
          (mrrChartType === "software" && nickname?.startsWith("Premium")) ||
          (mrrChartType === "bookkeeping" &&
            nickname?.startsWith("Bookkeeping"))
        ) {
          if (
            (createdAt <= firstDay || startedAt <= firstDay) &&
            (canceledAt <= lastDay || !canceledAt) &&
            (status === "active" || status === "trialing")
          ) {
            if (interval === "year") {
              mrrData[i] += amount / 100 / 12;
            } else if (interval === "month") {
              mrrData[i] += amount / 100;
            } else if (interval === "week") {
              mrrData[i] += (amount / 100) * 4;
            }
          }

          if (
            (createdAt <= firstDay1 || startedAt <= firstDay1) &&
            (canceledAt <= lastDay1 || !canceledAt) &&
            (status === "active" || status === "trialing")
          ) {
            if (interval === "year") {
              mrrData1[i] += amount / 100 / 12;
            } else if (interval === "month") {
              mrrData1[i] += amount / 100;
            } else if (interval === "week") {
              mrrData[i] += (amount / 100) * 4;
            }
          }
        }
      });
      // });
    }
    console.log("YTD mrrData", mrrData);
    setMrrChartData([
      {
        name: "This Year",
        data: mrrData.map((item) => Number(item.toFixed(2))),
      },
      {
        name: "Last Year",
        data: mrrData1.map((item) => Number(item.toFixed(2))),
      },
    ]);
    setLastMrr(mrrData[mrrData.length - 1]);
    const options = {
      ...lineChartOptionsTotalSpent,
      xaxis: {
        ...lineChartOptionsTotalSpent.xaxis,
        categories: mrrData.map((_val, i) => `${i + 1}`),
      },
    } as ApexOptions;
    console.log({ options });
    setMrrChartOptions(options);
  };

  const getMTDChartData = () => {
    setMrrChartData1([
      {
        name: "This Month",
        data: [],
      },
      {
        name: "Last Month",
        data: [],
      },
    ]);
    setMrrChartOptions1({});
    setLastMrr1(0);

    const mrrData: number[] = [];
    const mrrData1: number[] = [];
    const d = new Date();
    for (let i = 0; i < d.getDate(); i++) {
      mrrData.push(0);
      mrrData1.push(0);
      const firstDay =
        new Date(d.getFullYear(), d.getMonth(), i - 1).getTime() / 1000;
      const lastDay =
        new Date(d.getFullYear(), d.getMonth(), i).getTime() / 1000;

      const firstDay1 =
        new Date(d.getFullYear(), d.getMonth() - 1, i - 1).getTime() / 1000;
      const lastDay1 =
        new Date(d.getFullYear(), d.getMonth() - 1, i).getTime() / 1000;

      // clients.forEach((client) => {
      // client.
      subscriptions.forEach((subscription: any) => {
        const {
          created: createdAt,
          canceled_at: canceledAt,
          start_date: startedAt,
          ended_at: endedAt,
          plan: { interval, amount, nickname },
          status,
        } = subscription;

        if (
          mrrChartType1 === "all" ||
          (mrrChartType1 === "software" && nickname?.startsWith("Premium")) ||
          (mrrChartType1 === "bookkeeping" &&
            nickname?.startsWith("Bookkeeping"))
        ) {
          if (
            (createdAt <= firstDay || startedAt <= firstDay) &&
            (canceledAt <= lastDay || !canceledAt) &&
            (status === "active" || status === "trialing")
          ) {
            if (interval === "year") {
              mrrData[i] += amount / 100 / 12;
            } else if (interval === "month") {
              mrrData[i] += amount / 100;
            } else if (interval === "week") {
              mrrData[i] += (amount / 100) * 4;
            }
          }

          if (
            (createdAt <= firstDay1 || startedAt <= firstDay1) &&
            (canceledAt <= lastDay1 || !canceledAt) &&
            (status === "active" || status === "trialing")
          ) {
            if (interval === "year") {
              mrrData1[i] += amount / 100 / 12;
            } else if (interval === "month") {
              mrrData1[i] += amount / 100;
            } else if (interval === "week") {
              mrrData[i] += (amount / 100) * 4;
            }
          }
        }
      });
      // });
    }

    setMrrChartData1([
      {
        name: "This Month",
        data: mrrData.map((item) => Number(item.toFixed(2))),
      },
      {
        name: "Last Month",
        data: mrrData1.map((item) => Number(item.toFixed(2))),
      },
    ]);
    setLastMrr1(mrrData[mrrData.length - 1]);
    const options = {
      ...lineChartOptionsTotalSpent,
      xaxis: {
        ...lineChartOptionsTotalSpent.xaxis,
        categories: mrrData.map((_val, i) => `${i + 1}`),
      },
    } as ApexOptions;
    setMrrChartOptions1(options);
  };

  useEffect(() => {
    getYTDChartData();
  }, [clients, subscriptions, mrrChartType]);

  useEffect(() => {
    getMTDChartData();
  }, [clients, subscriptions, mrrChartType1]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <Card align="center" direction="column" w="100%">
          <Flex justify="space-between" align="start" px="10px" pt="5px">
            <Flex flexDirection="column" align="start" me="20px">
              <Flex w="100%">
                <Text
                  me="auto"
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Monthly Recurring Revenue ( Year to date )
                </Text>
              </Flex>
              <Flex align="end">
                <Text
                  color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {lastMrr.toFixed(2)}
                </Text>
                <Text
                  ms="6px"
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                >
                  USD
                </Text>
              </Flex>
            </Flex>
            <Flex align="center">
              <Select
                fontSize="sm"
                variant="subtle"
                defaultValue="monthly"
                width="unset"
                fontWeight="700"
                value={mrrChartType}
                onChange={(e) => setMrrChartType(e.target.value)}
              >
                <option value="all">All</option>
                <option value="software">Software</option>
                <option value="bookkeeping">Bookkeeping</option>
              </Select>
            </Flex>
          </Flex>
          <Box h="240px" mt="auto">
            <Chart
              options={mrrChartOptions}
              series={mrrChartData}
              type="line"
              width="100%"
              height="100%"
            />
          </Box>
        </Card>

        <Card align="center" direction="column" w="100%">
          <Flex justify="space-between" align="start" px="10px" pt="5px">
            <Flex flexDirection="column" align="start" me="20px">
              <Flex w="100%">
                <Text
                  me="auto"
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Monthly Recurring Revenue ( Month to date )
                </Text>
              </Flex>
              <Flex align="end">
                <Text
                  color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {lastMrr1.toFixed(2)}
                </Text>
                <Text
                  ms="6px"
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                >
                  USD
                </Text>
              </Flex>
            </Flex>
            <Flex align="center">
              <Select
                fontSize="sm"
                variant="subtle"
                defaultValue="monthly"
                width="unset"
                fontWeight="700"
                value={mrrChartType1}
                onChange={(e) => setMrrChartType1(e.target.value)}
              >
                <option value="all">All</option>
                <option value="software">Software</option>
                <option value="bookkeeping">Bookkeeping</option>
              </Select>
            </Flex>
          </Flex>
          <Box h="240px" mt="auto">
            <Chart
              options={mrrChartOptions1}
              series={mrrChartData1}
              type="line"
              width="100%"
              height="100%"
            />
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default MRR;
